<template>
    <div>
        <b-row>
            <b-col sm="12">                
                <b-card>         
                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDate"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDate"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="btnFilter()"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row> 

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect"
                                v-model="sortBy"
                                :options="sortOption"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc"
                                size="sm"
                                :disabled="!sortBy"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput"
                                v-model="filter"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="filter = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                            
                    <json-excel :data="tableExport()" name="cantidad_de_formularios_por_tarifario.xls"></json-excel>
                    <b-table
                        ref="table2Ref"
                        :fields="fields"
                        :items="items"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    >               
                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>
                        
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from '@axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {                
                perPage: 50,
                pageOptions2: [10, 15, 20, 25, 30],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,   
                filterOn: [],     
                fields: [],
                items: [],
                
                starDate: "",
                endDate: "",
                
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                }
            }
        },
        computed: {         
            sortOption(){
                 return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            },  
        },
        beforeMount() {
            this.getDataTable();
        },
        methods: {
            async getDataTable () {
                await axios
                    .get(`/reporteria/table-5?filterStartDate=${this.starDate}&filterEndDate=${this.endDate}`)
                    .then(response => {
                        if(response){
                                                                                   
                            this.fields = response.data.fields;
                            this.items = response.data.data; 
                            this.totalRows = response.data.data.length;
                            this.currentPage = 1;
                            this.sortBy = '';
                            this.sortDesc = false;
                            this.sortDirection = 'asc';
                            this.filter = null;
                        }
                    })
            },
            async btnFilter () {
               await this.getDataTable();
                this.$refs['table2Ref'].refresh();
            },
            tableExport () {

                let array = [];

                for (let a=0; a<this.items.length; a++)
                {
                    let objBody = {
                        '#': a + 1,
                        'PROVEEDOR': this.items[a]["proveedor"],
                        'RUC': this.items[a]["ruc"],
                        'RUBRO': this.items[a]["rubro"],
                        'MES/AÑO': this.items[a]["mes_anno"],
                        'DIFERENCIA TOTAL DE TARIFARIO': this.items[a]["tariff_difference"],                       
                    };

                    array.push(objBody);
                }
                
                return array;
            },            
            onFiltered (filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            }           
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
